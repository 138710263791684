<template>

    <pdf-view :url = "standard.fileUrl"/>
   
</template>

<script>

import pdfView from "@/components/all/pdfView"; //引入尾部组件

export default {
  name: 'StandardDetail',
  components: {
   
    pdfView,
  },

  data() {
    return {   
      standard: {},
      id: '',
      title: '',
      primary: 1, //一级菜单的选中样式
      istrue: true, //刷新页面
      crumblist: [
        {
          name: "首页",
          path: "/home",
          query: {},
        },
        {
          name: "人工智能标准",
          path: "/standard",
          query: {},
        },
        {
          name: "标准详细信息",
          path: "",
          query: {},
        },
      ],
    }
  },
  
  created() {
    this.init()
    this.getdata();
  },
  methods: {
    init(){
      this.id = this.$route.params.id
      console.log("   standard id " ,   this.id)
    },
    getdata() {
      this.$api.getStandardDetail(this.id).then((res) => {
        this.standard = res.data;
        this.title = this.standard.title
        console.log("  get standard " , this.standard)
      });
    },
  },
};
</script>
